// Normalize Styles
//@import 'node_modules/normalize.css/normalize';

// Import Modules
//@import '../_modules/link/link';

// SCSS
@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'animate.scss';


// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

// Bootstrap Fixes
.card .card-img-top{
  width: 100%;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

@import 'theme';