@import 'variables';
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);

body {
  font-family: 'Roboto', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  color: #2a3237;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.parallax {
  position: absolute;
  overflow: hidden;
  width: 100%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.parallax img {
  width: 100%;
  height: 100%;
}
/* Preloader */

.preloader {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: white;
}

.preloader img {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}

.preloader div {
  display: none;
  /* Preload the Second Pricing Image */
  background: url(../images/pricing2.jpg) no-repeat 9999px 9999px;
  background-position: 9999px 9999px;
}

/* Typography */

p {
  font-size: 18px;
  line-height: 1.5;
  color: #8a8e91;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'Avenir Next', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  position: relative;
  margin: 10px 0;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul.white-list {
  padding: 0;
  list-style-type: none;
}

ul.white-list li {
  font-size: 18px;
  margin: 10px 0;
  color: #fff;
}

ul.white-list li:before {
  content: ' ';
  position: relative;
  top: -3px;
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 15px;
  background: white;
}

header {
  position: relative;
  width: 100%;
  color: white;
  background: rgba(28, 36, 65, 0.93);
  background: url('../images/header.jpg');
  background-size: cover;
}

header .table {
  display: table;
  height: 100%;
}

header .container {
  height: 100%;
}

header .header-text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  color: white;
}

header .typed {
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
}

header .typed-cursor {
  font-size: 60px;
  display: inline-block;
  margin: 0 10px;
  color: $brand-primary;
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

a {
  text-decoration: none;
  color: $brand-primary;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* Navigation Bar ( Navbar ) */

nav.navbar {
  position: absolute;
  z-index: 9500;
  width: 100%;
  width: 100vw;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

nav.navbar .navbar-nav li.active a:not(.btn) {
  color: $brand-primary !important;
}

nav.navbar-fixed-top {
  z-index: 9499;
  top: 0;
  padding: 25px 0;
  opacity: 0;
  background: white;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.05);
}

nav .navbar-nav > li > a:not(.btn) {
  color: #fff;
}


nav.navbar-fixed-top .navbar-nav > li > a:not(.btn) {
  color: #bbb;
}

.icon-bar {
  background: #bbb;
}

.navbar-toggler{
  float: right;
  font-size: 2.25rem;
}

/* Blink Cursor */

.blink {
  position: relative;
  top: 4px;
  display: inline-block;
  width: 4px;
  height: 50px;
  height: 5vh;
  margin: 0 10px;
}

.navbar {
  top: 50px;
}

.container {
  position: relative;
  z-index: 1;
}
/* Sections */
section {
  position: relative;
}

.section {
  padding: 40px 0;
  background: #fff;
}

.section-padded {
  padding: 140px 0 40px;
}

.cut-top,
.cut-portfolio {
  content: ' ';
  position: absolute;
  z-index: 1;
  top: -80px;
  left: 0;
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-right: 30px solid white;
}

.cut-bottom {
  content: ' ';
  position: absolute;
  z-index: 1;
  bottom: -80px;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 80px solid transparent;
  border-left: 30px solid white;
}

.intro-tables {
  top: -130px;
  position: relative;
}

.intro-table {
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: repeat;
  background-position: 0% 0%;
}

.intro-table-first {
  background-image: url('../images/table-1.jpg');
}

.intro-table-hover {
  -webkit-transition: background-image 0.3s ease, background-position 0.3s;
  transition: background-image 0.3s ease, background-position 0.3s;
  background-image: url('../images/table-2.jpg');
}

.intro-table-hover h4 {
  -webkit-transform: translateY(170px);
  transform: translateY(170px);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.intro-table-hover:hover {
  background-image: url('../images/table-2-hover.jpg');
  background-position: 50% 50%;
}

.intro-table-third {
  background-image: url('../images/table-3.jpg');
}

.intro-table-hover .expand {
  margin: 30px;
  margin-top: 120px;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s ease, opacity 0.3s;
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}

.intro-table-hover:hover h4 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.intro-table-hover:hover .expand {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.intro-table-hover .hide-hover {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.intro-table-hover:hover .hide-hover {
  opacity: 0;
}

.intro-tables .intro-table {
  position: relative;
  width: 100%;
  height: 300px;
  margin: 20px 0;
}

.intro-tables .intro-table .heading {
  margin: 0;
  padding: 30px;
}

.intro-tables .intro-table .small-heading {
  margin: 0;
  padding: 0 30px;
}

.intro-tables .intro-table .bottom {
  position: absolute;
  bottom: 0;
}

.intro-tables .intro-table .owl-schedule .schedule-row {
  padding: 10px 30px;
  color: white;
  transition: all 0.3s ease;
}

.owl-schedule .schedule-row:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.owl-testimonials .author {
  margin-top: 50px;
}

.ripple-effect {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  -webkit-animation: ripple-animation 2s;
  animation: ripple-animation 2s;
}

@-webkit-keyframes ripple-animation {
  from {
    opacity: 0.2;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(100);
    transform: scale(100);
  }
}

@keyframes ripple-animation {
  from {
    opacity: 0.2;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(100);
    transform: scale(100);
  }
}

.services {
  margin: 40px 0;
}

.service {
  width: 100%;
  height: 320px;
  margin: 80px 0;
  text-align: center;
  border: 1px solid #ddd;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.service .icon-holder {
  position: relative;
  top: 100px;
  display: inline-block;
  margin-bottom: 40px;
  padding: 10px;
  background: white;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.service .heading {
  position: relative;
  top: 80px;
  -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.service .icon-holder > img.icon {
  width: 40px;
}

.service:hover {
  border-color: $brand-primary;
}

.service:hover .icon-holder {
  top: -30px;
}

.service:hover .heading {
  top: -30px;
}

.service .description {
  width: 80%;
  margin: 0 auto;
  opacity: 0;
  -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.service:hover .description {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}


//
/* Portfolio */
//
#portfolio{
  padding-bottom: 150px;
  .row.title {
    margin: 50px 0;
  }
  .card{
    overflow: hidden;
  }
}

.portfolio {
  margin: 80px 0;
  padding-bottom: 60px;
  background: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07);
}

.portfolio .cover .overlay {
  height: 250px;
  padding-top: 60px;
  opacity: 0;
  background: rgba(0, 168, 255, 0.9);
  -webkit-transition: opacity 0.45s ease;
  transition: opacity 0.45s ease;
}

.portfolio:hover .cover .overlay {
  opacity: 1;
}

.portfolio .avatar {
  position: relative;
  z-index: 2;
  margin-top: -60px;
  border-radius: 50%;
}


//
/* Pricing */
//
#pricing {
  background: $brand-primary url('../images/pricing1.jpg') no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transition: background-image 0.6s linear 0.3s;
  transition: background-image 0.6s linear 0.3s;
}

.owl-pricing img {
  width: 100%;
}

.owl-pricing,
.pricings {
  margin-top: 100px;
  margin-bottom: 100px;
}

.pricing {
  position: relative;
  width: 100%;
}

.pricings .pricing .box-main,
.pricings .pricing .box-second {
  position: relative;
  left: 25%;
  display: inline-block;
  width: 50%;
  height: 300px;
  padding: 50px 40px;
  background: #bbb;
  -webkit-transition: -webkit-transform 0.3s, background-image 0.3s, opacity 0.3s;
  transition: transform 0.3s, background-image 0.3s, opacity 0.3s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.pricings .pricing .box-main {
  z-index: 10;
  padding-top: 40px;
}

.pricings .pricing .box-main:not(.active) {
  cursor: pointer;
}

.pricings .pricing .box-main .info-icon {
  font-size: 14px;
  position: absolute;
  top: 20px;
  right: 20px;
  pointer-events: none;
  opacity: 0;
  color: #fff;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.pricings .pricing .box-main:not(.active) .info-icon {
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.pricings .pricing .box-main:not(.active):hover {
  background: #afafaf;
}

.pricings .pricing .box-main.active {
  background: $brand-primary;
  -webkit-transform: translateX(-99%);
  -ms-transform: translateX(-99%);
  transform: translateX(-99%);
}

.pricings .pricing .box-second {
  position: absolute;
  top: 0;
  right: 0% !important;
  left: auto;
  opacity: 0;
  background: #afafaf;
}

.pricings .pricing .box-second.active {
  opacity: 1;
  background: $brand-primary;
}

.pricings .pricing.active .box-main,
.pricings .pricing .box-second {
  background: $brand-primary;
}

.pricings .pricing .box-main a.btn {
  margin-top: 50px;
}

.owl-twitter i.icon {
  font-size: 36px;
  margin-bottom: 60px;
  color: white;
}


//
/* Footer */
//
footer {
  padding: 140px 0 40px;
  background: url('../images/footer.jpg');
  -webkit-background-size: cover;
  background-size: cover;

  p{
    color: #fff;
    font-size: 14px;
  }
}

footer .open-blink {
  content: ' ';
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 0 20px;
  border-radius: 50%;
  background-color: #4caf50;
  -webkit-animation-name: flash;
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

footer .open-blink:before {
  content: ' ';
  position: absolute;
  top: -8px;
  left: -8px;
  display: inline-block;
  width: 30px;
  height: 30px;
  opacity: 0.1;
  border-radius: 50%;
  background-color: #4caf50;
}


footer .bottom-footer {
  margin-top: 150px;
}

footer .social-footer {
  padding: 0;
  list-style: none;
}

footer .social-footer li {
  display: inline-block;
  margin: 0 10px;
}

footer .social-footer li a {
  font-size: 24px;
  color: #fff;
}

footer .social-footer li:hover a {
  color: $brand-primary;
}


//
/* Form Control */
//
.form-control {
  font-size: 18px;
  position: relative;
  left: 0;
  height: auto;
  padding: 20px 30px;
  border: 1px;
  border-radius: 0;
  box-shadow: 0;
}

.form-control.form-white {
  color: #fff;
  border: 2px solid white;
  background: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.form-control.form-white::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

.form-control.form-white:-moz-placeholder {
  opacity: 1;
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

.form-control.form-white::-moz-placeholder {
  opacity: 1;
  /* Mozilla Firefox 19+ */
  color: #fff;
}

.form-control.form-white:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

.form-control.form-white:focus {
  background: rgba(255,255,255,0.2);
}


//
/* Popup */
//
.modal-popup {
  position: relative;
  padding: 45px 30px;
  text-align: center;
  background: url('../images/popup.jpg');
  box-shadow: none;
  border-radius: 2px;
}

.modal-popup a.close-link {
  font-size: 22px;
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
}

.popup-form {
  width: 90%;
  max-width: 375px;
  margin: 60px auto;
}

.popup-form .form-control {
  margin: 20px 0;
}

.popup-form .form-control.dropdown {
  text-align: left;
}

.popup-form .form-control.dropdown:after {
  content: '\f0d7';
  font-family: 'FontAwesome';
  display: inline-block;
  float: right;
  color: white;
}

.popup-form .dropdown .dropdown-menu {
  top: 65px;
  width: 100%;
  padding: 0;
  border: 2px solid white;
  border-top: 0;
  border-radius: 0;
  background: white;
  box-shadow: none;
}

.popup-form .dropdown .dropdown-menu li {
  font-size: 16px;
  width: 100%;
  background: transparent;
}

.popup-form .dropdown .dropdown-menu li a {
  width: 100%;
  padding: 15px 30px;
  color: $brand-primary;
}

.popup-form .dropdown .dropdown-menu li:hover a {
  color: #fff;
  background: $brand-primary;
}

.portfolio-entry{
  text-align: center;

  .modal-header{
    padding: 20px;
  }

   button.close {
    font-size: 32px;
    position: absolute;
    top: 15px;
    right: 30px;
  }

  .modal-body{
    position: relative;
    padding: 45px 30px;
    box-shadow: none;
    border-radius: 2px;
  }

  img{
    border-radius: 3px;
  }

  h3{
    margin: 20px 0;
    a{
      margin: -5px 0 0 15px;
    }
  }
  p{
    text-align: left;
    font-size: 16px;
  }
}

/* Checkbox */

.checkbox-holder {
  white-space: nowrap;
}

.checkbox {
  position: relative;
  display: block;
}

.checkbox {
  position: relative;
}

.checkbox label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 2px solid white;
  background: transparent;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.checkbox input[type=checkbox]:focus + label:before {
  background: rgba(255,255,255,0.2);
}

.checkbox label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  opacity: 0;
  background: white;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.checkbox input[type=checkbox]:checked + label:after {
  opacity: 1;
}

.checkbox-holder span {
  position: relative;
  display: inline-block;
  margin: 0 0 0 10px;
  white-space: normal;
  color: #fff;
}

.btn.btn-submit {
  width: 100%;
  margin-top: 30px;
  color: $brand-primary;
  border: 2px solid #fff;
  background: #fff;
}

.btn.btn-submit:focus {
  font-weight: bold;
}

.btn.btn-submit:hover {
  color: $brand-primary;
  background: #fff;
}

/* Mobile Nav */
.mobile-nav {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: table;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  background: rgba(255, 255, 255, 0.98);
  -webkit-transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.mobile-nav.active {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.mobile-nav ul {
  display: table-cell;
  padding: 0;
  list-style: none;
  vertical-align: middle;
}

.mobile-nav ul li {
  margin: 25px 0;
}

.mobile-nav ul li a:not(.btn) {
  color: #aaa;
}

.mobile-nav a.close-link {
  font-size: 24px;
  position: absolute;
  bottom: 0px;
  left: calc(50% - 10px);
  left: 0;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  background: $brand-primary;
}

.row.title {
  padding: 0 20px;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}
/* Colors */

.white {
  color: white;
}

.light-white {
  color: rgba(255, 255, 255, 0.5);
}

.white-bg {
  background: white;
}

.gray-bg {
  background: #f7f7f7;
}

.blue {
  color: $brand-primary;
}

.blue-bg {
  background: $brand-primary;
}

.muted {
  color: #989da0;
}

.margin-top {
  margin-top: 150px;
}

@media(max-width:992px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 12px;
  }
  .section {
    padding: 30px 0;
  }
}

/* Media Queries */

@media(max-width: 768px) {
  .pricing {
    margin-bottom: 30px;
  }
  .pricings .pricing .info-icon {
    display: none;
  }
  .pricings .pricing .box-main,
  .pricings .pricing .box-second {
    left: 0;
    width: 100%;
    padding: 50px 50px 0;
    text-align: left;
    background: $brand-primary;
  }
  .pricings .pricing .box-main.active {
    background: $brand-primary;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
  .pricings .pricing .box-second {
    position: relative;
    opacity: 1;
  }
  .popup-form {
    width: 100%;
    margin: 60px auto;
  }
  .modal {
    padding: 0 10px !important;
  }
  .popup-form .form-control:not(.dropdown):focus {
    position: relative;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media(max-width: 400px) {
  header .typed-cursor {
    display: none;
  }
  .pricings .pricing .box-second {
    padding-top: 0;
  }
}





